.JokesList {
    background-color: white;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1);
}

.Joke {
    border-bottom: 2px solid #eee;
    padding: 1rem;
    display: flex;
    align-items: center;
}

.Joke-VoteButtons{
    display: flex;
    align-items: center;
}

.Joke-VoteButtons i{
    font-size: 1.25rem;
    cursor: pointer;
}

    .Joke-VoteButtons span {
        width: 30px;
        height: 30px;
        margin-inline: 5px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1);
    }

.Joke-Text {
    margin-inline: 1rem;
    font-size: 0.9rem;
    line-height: 1.1rem;
    flex-grow: 1;
}

.Joke-Smiley {
    justify-self: end;
    display: flex;
    align-items: center;
}

    .Joke-Smiley i {
        font-size: 1.5rem;
        border-radius: 50%;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1);
    }

    .Joke-Smiley i:hover{
        animation: 0.7s ease-in-out spin;
    }

@media(min-width: 750px){
    .JokesList{
        height: 80%;
        width: 60%;
        overflow-y: scroll;
    }
}

@keyframes spin{
    0%{
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(20deg);
    }
    60% {
        transform: rotate(0deg);
    }
    80% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}