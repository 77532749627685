*{
    box-sizing: border-box;
}

.Homepage {
    padding: 1rem;
    background: linear-gradient( 135deg, rgba(179, 229, 252, 1) 0%, rgba(179, 229, 252, 1) 50%, rgba(240, 98, 146, 1) 50%, rgba(240, 98, 146, 1) 100% );
    font-family: "Rubik", sans-serif;
    color: #78909c;
}

@media(min-width: 750px){
    .Homepage {
        height: 100vh;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}