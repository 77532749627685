.Spinner {
    height: 100vh;
    background: linear-gradient( 135deg, rgba(179, 229, 252, 1) 0%, rgba(179, 229, 252, 1) 50%, rgba(240, 98, 146, 1) 50%, rgba(240, 98, 146, 1) 100% );
    font-family: "Rubik", sans-serif;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Spinner span{
    margin-top: 1.5rem;
    font-size: 2rem;
    font-weight: 700;
}
